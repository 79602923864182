import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { useCreditorSelf } from "@gocardless/api/dashboard/creditor";
import { useRouter } from "next/router";

import { VerificationBannerVariant } from "./VerificationBannerConfig";

import { useVerificationStatus } from "src/common/hooks/useVerificationStatus";
import { getConfig, getSite } from "src/common/config";
import { useOrganisation } from "src/queries/organisation";
import { useGcSasRemediation } from "src/components/routes/Setup/common/hooks/useGcSasRemediation";
import {
  LowRiskThreshold,
  useLowRiskThreshold,
} from "src/hooks/useLowRiskThreshold/useLowRiskThreshold";
import { rawPaths } from "src/common/routing/routes";
import { useActivationDiscount } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";
import { useActivationDiscountCanExtend } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscountCanExtend";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

export enum VerificationBannerEvents {
  VERIFICATION_BANNER_LOADED = "verification_banner_loaded",
  EXTEND_CLICKED = "extend_clicked",
  CLOSE_CLICKED = "close_clicked",
}
interface UseVerificationBanner {
  getVariant: () => VerificationBannerVariant | null;
  remainingDays: number | undefined;
  digits: string[] | undefined;
  emitEvent: (name: VerificationBannerEvents) => void;
}

const useVerificationBanner = (): UseVerificationBanner => {
  const router = useRouter();
  const { status, collectionsPermitted } = useVerificationStatus();
  const { gcSasRemediationPending } = useGcSasRemediation();
  const { remainingDays, digits } = useActivationDiscount();
  const { canExtendDiscount } = useActivationDiscountCanExtend();
  const organisation = useOrganisation();
  const { data: creditor } = useCreditorSelf(
    organisation?.links?.primary_creditor || null
  );
  const lowRiskThreshold = useLowRiskThreshold();
  const { isVariationOn: isRewardsEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_REWARDS_AND_BOOSTS,
  });

  const hasPendingVerification =
    status === CreditorsVerificationStatus.ActionRequired;
  const hasInReviewVerification =
    status === CreditorsVerificationStatus.InReview;

  const hasIncentiveDiscount = !!remainingDays && !!digits;

  // don't show for legacy multi-creditor orgs as verifications don't
  // work the same way on the primary creditor
  const skipVerificationBanner =
    organisation?.is_multi_creditor && !organisation.links?.payment_provider;

  const billingOverdue = organisation?.billing_overdue;
  const adaPayoutsDisabled =
    router.route !== rawPaths["Payouts"] &&
    !!creditor?.creditors?.ada_review_needed;

  const getVariant = () => {
    if (skipVerificationBanner) {
      return null;
    }
    if (creditor?.creditors?.blocked_to_reonboard) {
      return VerificationBannerVariant.BLOCKED_TO_REONBOARD;
    }
    if (gcSasRemediationPending) {
      return VerificationBannerVariant.GC_SAS_REMEDIATION_PENDING;
    }
    if (billingOverdue) {
      return VerificationBannerVariant.BILLING_OVERDUE;
    }
    if (hasIncentiveDiscount) {
      if (hasInReviewVerification) {
        // we don't want to show anything if the merchant is in review
        // to avoid any frustration as we don't expect any action from them.
        // We also want to show extent banner if they are part of rewards experiment
        return isRewardsEnabled && canExtendDiscount
          ? VerificationBannerVariant.EXTEND_INCENTIVE
          : null;
      }
      if (lowRiskThreshold === LowRiskThreshold.LEVEL_0) {
        if (canExtendDiscount) {
          return VerificationBannerVariant.EXTEND_INCENTIVE;
        } else {
          return VerificationBannerVariant.CREATE_PAYMENTS_WITH_INCENTIVE;
        }
      }
      if (
        lowRiskThreshold === LowRiskThreshold.LEVEL_1 ||
        lowRiskThreshold === LowRiskThreshold.LEVEL_2 ||
        lowRiskThreshold === LowRiskThreshold.LEVEL_3
      ) {
        return VerificationBannerVariant.FINISH_SETUP;
      }
      if (hasPendingVerification || !collectionsPermitted) {
        return VerificationBannerVariant.FINISH_SETUP_WITH_INCENTIVE;
      }
      if (canExtendDiscount) {
        return VerificationBannerVariant.EXTEND_INCENTIVE;
      } else {
        return VerificationBannerVariant.CREATE_PAYMENTS_WITH_INCENTIVE;
      }
    } else {
      if (hasInReviewVerification) {
        // we don't want to show anything if the merchant is in review
        // to avoid any fustration as we don't expect any action from them
        return null;
      }
      if (!collectionsPermitted) {
        return VerificationBannerVariant.FINISH_SETUP;
      }
      if (hasPendingVerification) {
        return VerificationBannerVariant.ADD_DETAILS;
      }
      if (adaPayoutsDisabled) {
        return VerificationBannerVariant.ADA_PAYOUTS_DISABLED;
      } else {
        return null;
      }
    }
  };

  const emitEvent = (name: VerificationBannerEvents) => {
    const dashboardUrl = getConfig().client.urls.dashboard ?? getSite();
    window.parent.postMessage(
      {
        name,
        payload: {},
      },
      dashboardUrl
    );
  };

  return { getVariant, remainingDays, digits, emitEvent };
};

export default useVerificationBanner;
